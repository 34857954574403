<template>
	<div>
		<indexPc class="kuai_pc" v-if="!isMobileDevice"></indexPc>
		<div class="kuai_mobile" v-else>
			<div
				class="Main width_1200 margin_auto flex"
				style="padding-bottom: 20px"
			>
				<div class="app_kuaixun">
					<div
						class="app_kuaixunList"
						v-if="newarticleList.length > 0 || !isNetRequestBool"
					>
						<div v-for="(items, indexs) in newarticleList" :key="indexs">
							<div class="flex align_items_c kxSection">
								<div class="kxDate">
									{{ items.release_time }}
								</div>
								<div class="kxDate_diff">{{ items.diff_time }}</div>
								<div class="kxDate_week">{{ items.week_time }}</div>
							</div>
							<div class="kxList">
								<el-steps
									direction="vertical"
									:active="1"
									style="padding-top: 10px"
								>
									<el-step
										v-for="(item, index) in items.subList"
										:key="index"
										class="kx_pc_item"
									>
										<template slot="description">
											<div class="kxItemSub">
												<router-link
													target="_blank"
													:to="{ name: '/kuaixun_wz', params: { id: item.id } }"
												>
													<div
														class="pointer hovercolor kxItemSubTitle"
														:prohibitTran="item.prohibit_tran"
														:style="{
															color: item.isRecommend ? '#1545D6' : '',
														}"
														>{{
															localLanguage == 2
																? item.title
																	? item.title
																	: item.title
																: item.title
														}}
													</div>
												</router-link>
												<router-link
													target="_blank"
													:to="{ name: '/kuaixun_wz', params: { id: item.id } }"
												>
													<p
														class="kxItemSubContent"
														:prohibitTran="item.prohibit_tran"
														v-html="
															localLanguage == 2
																? item.content
																	? item.content
																	: item.content
																: item.content
														"
													></p>
												</router-link>
												<div
													class="flex justify_content_sb align_items_c kxstepsBoxapp"
												>
													<div class="kxTimeS">{{
														item.release_time.substr(10, 6)
													}}</div>
													<div
														class="flex align_items_c btnitemapp fx"
														@click="copyText(item.id, item.title)"
													>
														<img
															class="btnitemappimg1"
															src="https://res.metaera.hk/resources/assets/images/kxwz/share_fang.svg"
															alt=""
														/>
														<div>分享</div>
													</div>
												</div>
											</div>
										</template>
									</el-step>
								</el-steps>
							</div>
						</div>
						<scrollToBottom @reachBottom="getDataListPage"></scrollToBottom>
					</div>
				</div>
				<div class="MainLeft">
					<div class="kxTime flex align_items_c justify_content_sb">
						<div class="flex align_items_c">
							<div class="tabList flex align_items_c">
								<div
									class="navItem pointer"
									v-for="(item, index) in categoryLists"
									:key="index"
									@click="handleClick(item, index)"
									:class="{ active: active == index }"
								>
									{{ item.name }}
								</div>
							</div>
						</div>
					</div>
					<div
						class="MainRight_2 MainRight_3 margin_top_20 steps kxsteps"
						v-if="newarticleList.length > 0 || !isNetRequestBool"
						style="margin-top: -40px"
					>
						<div v-for="(items, indexs) in newarticleList" :key="indexs">
							<div class="flex align_items_c" style="padding: 23px 0 10px">
								<div class="kxDate">{{ items.release_time }}</div>
								<div
									class="kxDate_co"
									style="margin-left: 10px"
									v-if="items.diff_time != null"
								>
									<div
										class="kxDate_diff"
										style="font-size: 12px; color: #000000"
										>{{ items.diff_time }}</div
									>
									<div
										class="kxDate_week"
										style="font-size: 12px; color: #767680"
										>{{ items.week_time }}</div
									>
								</div>
								<div
									class="kxDate_week"
									style="font-size: 18px; color: #767680; margin-left: 10px"
									v-else
									>{{ items.week_time }}</div
								>
							</div>
							<el-steps direction="vertical" :active="1" class="wzitem_main">
								<el-step
									v-for="(item, index) in items.subList"
									:key="index"
									class="kx_pc_item"
								>
									<template slot="description">
										<div class="contentBox">
											<router-link
												target="_blank"
												:to="{ name: '/kuaixun_wz', params: { id: item.id } }"
											>
												<div
													class="stepsVal ellipsis_multiple pointer hovercolor"
													:prohibitTran="item.prohibit_tran"
													:style="{ color: item.isRecommend ? '#1545D6' : '' }"
												>
													{{
														localLanguage == 2
															? item.title
																? item.title
																: item.title
															: item.title
													}}
												</div>
											</router-link>
											<p
												class="contentStep"
												:prohibitTran="item.prohibit_tran"
												v-html="
													localLanguage == 2
														? item.content
															? item.content
															: item.content
														: item.content
												"
											></p>

											<div
												class="flex justify_content_sb align_items_c kxstepsBox"
											>
												<div class="stepsTime" v-if="item.release_time != null">
													{{ item.release_time.substr(10, 6) }}
													{{
														item.source_type == 2 || item.source_type == 10
															? " 律动BlockBeats"
															: item.source_type == 3
															? " Odaily星球日报"
															: item.source_type == 4
															? " PANews"
															: item.source_type == 6
															? " Followin"
															: item.source_type == 7
															? " 吴说区块链"
															: item.source_type == 11
															? " 深潮TechFlow"
															: ""
													}}
												</div>
												<div
													class="flex justify_content_sb align_items_c kxstepsBoxLeft"
												>
													<div
														class="flex align_items_c btnitem justify_content_c fx pointer"
														@click="showWeChat(item.id)"
													>
														<img
															src="https://res.metaera.hk/resources/assets/images/kxwz/kxwz_wechat.png"
															alt=""
														/>
													</div>
													<div
														class="flex align_items_c btnitem justify_content_c fx pointer"
														@click="twitterText(item.id, item.title)"
													>
														<img
															src="https://res.metaera.hk/resources/assets/images/event/12.png"
															alt=""
														/>
													</div>
													<div
														class="flex align_items_c btnitem justify_content_c fx pointer"
														@click="showPreView(item.id)"
													>
														<img
															src="https://res.metaera.hk/resources/assets/images/home/686.png"
															alt=""
														/>
													</div>
												</div>
											</div>
											<div class="kx_line"></div>
										</div>
									</template>
								</el-step>
							</el-steps>
						</div>
						<scrollToBottom @reachBottom="getDataListPage"></scrollToBottom>
					</div>
				</div>
				<div class="MainRight">
					<resou></resou>
					<!-- 主编推荐 -->
					<event type="recommend" title="主编推荐" style="margin-top: 30px">
					</event>
					<download></download>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { categoryLists, getArticleList } from "@/api/home";
import { myMixin } from "@/utils/mixin.js";
import clipboard from "clipboard";
import download from "./../../components/pc/download.vue";
import { newBus } from "../../components/pc/topHeader2.vue";
import indexPc from "./index_pc.vue";
import ChineseConv from "chinese-s2t";

export default {
	mixins: [myMixin],
	components: { download, indexPc },
	name: "",
	data() {
		return {
			lookTitle: false,
			categoryLists: [],
			articleList: [],
			active: 0,
			newarticleList: [],
			page: 1,
			size: 25,
			total: "",
			isNetRequestBool: false,
			loading: false,
			currentId: 0,
			localLanguage: 1,
		};
	},
	created() {
		this.getArticleList(
			{
				category_id: 0,
				type: 2,
				page: this.page,
				size: this.size,
			},
			0
		);
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	computed: {
		isMobileDevice() {
			return this.$store.state.app.isMobileDevice;
		},
	},
	methods: {
		showWeChat(id) {
			this.currentId = id;
		},
		showPreView(id) {
			let msg = "https://web.metaera.hk/news/" + id;
			clipboard.copy(msg);
			this.$message({ message: "复制成功", type: "success" });
		},
		twitterText(id, title) {
			var sharetitle = ChineseConv.s2t(title);
			window.open(
				"http://twitter.com/share?url=" +
					encodeURIComponent("https://web.metaera.hk/news/" + id) +
					"&text=" +
					encodeURIComponent(sharetitle) +
					"&display=popup&ref=plugin&src=share_button",
				"twitter",
				"height=500, width=750, top=" +
					100 +
					", left=" +
					100 +
					", toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
			);
		},
		copyText(id, title) {
			var sharetitle = ChineseConv.s2t(title);
			window.open(
				"http://twitter.com/share?url=" +
					encodeURIComponent("https://web.metaera.hk/news/" + id) +
					"&text=" +
					encodeURIComponent(sharetitle) +
					"&display=popup&ref=plugin&src=share_button",
				"twitter",
				"height=500, width=750, top=" +
					100 +
					", left=" +
					100 +
					", toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
			);
		},
		getDataListPage() {
			if (this.loading) {
				return;
			}
			if (this.page >= Math.ceil(this.total / this.size)) {
				return;
			}
			this.loading = true;
			this.page = this.page + 1;
			this.getArticleList(
				{
					category_id: 0,
					type: 2,
					page: this.page,
					size: this.size,
				},
				0
			);
		},
		getDataList() {
			categoryLists({ type: 2 })
				.then((res) => {
					if (res.code == 200) {
						this.getArticleList(
							{
								category_id: 0,
								type: 2,
								page: this.page,
								size: this.size,
							},
							0
						);
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		handleClick(item, index) {
			this.page = 1;
			this.active = index;
			this.getArticleList(
				{ category_id: item.id, type: 2, page: this.page, size: this.size },
				0
			);
		},
		getArticleList(obj, type) {
			let res = JSON.parse(sessionStorage.getItem("getKXArticleList1Data"));
			if (res != null && res.code == 200 && obj.page == 1) {
				this.analyzeData(res, obj);
			}
			getArticleList(obj)
				.then((res) => {
					if (res.code == 200) {
						if (obj.page == 1) {
							sessionStorage.setItem(
								"getKXArticleList1Data",
								JSON.stringify(res)
							);
						}
						this.analyzeData(res, obj);
					} else {
						// this.$message.error(res.message);
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		analyzeData(res, obj) {
			if (res.code == 200) {
				this.loading = false;
				this.articleList = res.data.list;
				this.total = res.data.total;
				if (obj.page == 1) {
					this.newarticleList = [];
				}
				this.newarticleList = this.getNewData(
					this.articleList,
					this.newarticleList
				);
				this.isNetRequestBool = true;
				// console.log(this.newarticleList);
			} else {
			}
		},
		getYearMonth(date) {
			// 将日期以空格隔开，即['2020-06-13', '17:10:09']
			date = (date + "").split(/[ ]+/);
			let result = "";
			let reg = /^(\d{4})-(\d{1,2})-(\d{1,2})$/;
			// 用截取出来的年月日进行正则表达式匹配
			reg.exec(date[0]);
			result = RegExp.$1 + "-" + RegExp.$2 + "-" + RegExp.$3;

			return result;
		},

		handleNotice() {
			let that = this;
			if (!("Notification" in window)) {
				alert("此浏览器不支持桌面通知！");
			}
			Notification.requestPermission().then(function (result) {
				if (result === "denied") {
					// console.log("被拒绝");
					return;
				}
				if (result === "default") {
					// console.log("默认");
					return;
				}
				that.notifyMsg();
			});
		},
		notifyMsg() {
			//通知
			const title = "通知栏";
			const options = {
				body: "这是消息主体", // 通知主体
				data: "",
				icon: "https://sponsors.vuejs.org/images/chrome_frameworks_fund.png", // 通知图标
			};
			let notification = new Notification(title, options);
			notification.onclick = () => {
				notification.close(); //关闭通知
			};
		},
	},
};
</script>
<style>
.kuai_pc {
	display: block;
}
.kuai_mobile {
	display: none;
}
@media screen and (max-width: 900px) {
	.kuai_pc {
		display: none;
	}
	.kuai_mobile {
		display: block;
	}
}
.kuai_mobile .el-step__icon-inner {
	display: none;
}
.kuai_mobile .el-step__icon {
	width: 9px;
	height: 9px;
}
.kuai_mobile .el-step__icon.is-text {
	/* background: #6974c8; */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	/* border: 4px solid; */
}
.kuai_mobile .el-step.is-vertical .el-step__line {
	left: 4px;
	width: 0px;
	border: 1px dashed #ccd1de;
	background-color: transparent;
}

.kuai_mobile .el-step__head.is-wait {
	color: #000000;
	border-color: #dedee1;
}
.kuai_mobile .el-step__head.is-finish {
	color: #000000;
	border-color: #dedee1;
}
.kuai_mobile .el-step__description {
	/* margin-top: 0px; */
	display: flex;
}

.kuai_mobile .contentStep,
.kuai_mobile .contentStep p,
.kuai_mobile .contentStep p span,
.kuai_mobile .contentStep p a {
	margin-top: 10px;
	text-align: justify;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px !important;
	font-weight: normal;
	line-height: 1.5;
	letter-spacing: 0em;

	color: #333;
}
.contentStep {
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	color: #333;
}
.contentStep img {
	display: none;
}
/* * {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: normal;
	letter-spacing: 0em;

	color: #333;
} */

.kx_line {
	width: 100%;
	height: 1px;
	margin-bottom: 20px;
}
</style>

<style scoped>
.Main {
	padding-top: 20px;
}

.MainLeft {
	width: 600px;
}

.kxTime {
	width: 100%;
}

.kxDate {
	font-size: 41px;
	line-height: 53px;
	font-family: DINPro-Medium, DINPro;
	font-weight: 500;
	color: #000;
}

.kxweek {
	margin-left: 7px;
}

.kxweek div:nth-child(1) {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.kxweek div:nth-child(2) {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #767680;
}

/* .tabList {
        margin-left: 21px;
    } */
.MainRight_2 {
	background: rgba(255, 255, 255, 0);
	opacity: 1;
	padding-left: 0px;
}
.navItem {
	margin-left: 34px;
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 500;
	color: #767680;
}

.setTitle div {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #000000;
	margin-right: 5px;
}

.setTitle img {
	width: 18px;
	height: 18px;
}

.btnitem {
	width: 23px;
	height: 23px;
	border-radius: 12px;
	margin-top: 7px;
	font-size: 12px;
	display: flex;
	margin-left: 10px;
}

.btnitem img {
	width: 14px;
	/* height: 18px; */
	margin-right: 6px;
}

.btnitemimg2 {
	height: 20px !important;
	width: auto !important;
}
.btnitem div {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a0a0a8;
}

.kxstepsBox {
	margin-top: 18px;
	margin-bottom: 2px;
	display: flex;
	justify-content: space-between;
}

.kxstepsBoxLeft {
	width: auto;
}

.kxstepsBox a {
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
	text-decoration: underline;
}

.active {
	color: #0056ff;
}
.setting {
	position: relative;
}

.setting img {
	display: block;
	cursor: pointer;
}

.setting .setting__list {
	display: block;
	position: absolute;
	left: -56px;
	top: 40px;
	z-index: 10;
	width: 118px;
	padding: 9px 13px;
	border-radius: 4px;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
	background: #fff;
	box-sizing: border-box;
}

.setting .setting__list li {
	display: block;
	height: 16px;
	padding: 5px 0;
}

.setting .setting__list li span {
	float: left;
	font-size: 14px;
	color: #666;
	line-height: 14px;
}

.switch {
	float: right;
	width: 24px;
	height: 13px;
	padding: 1px;
	border-radius: 8px;
	color: #666;
}

.switch i {
	display: block;
	width: 11px;
	height: 11px;
	background: #fff;
	border-radius: 50%;
	margin: 1px 2px;
}

.switch.remind {
	background: #dedee1;
}

.switch.remind-open {
	background: #ffb100;
}

.switch.remind-open i {
	float: right;
}

.js-lives__box .js-lives__date {
	float: inherit;
	margin: 45px 0;
}
.stepsTime {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: 300;
	line-height: 13px;
	letter-spacing: 0em;
	color: #b2b2b2;
	margin-top: 4px;
}
</style>

<style>
.el-step.is-vertical .el-step__main {
	padding-left: 10px !important;
}
.contentBox {
	width: 100%;
}
.kxsteps .stepsVal {
	margin-top: -4px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	font-size: 16px;
	line-height: 1.5;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.kxsteps .el-step__main {
	height: auto;
	padding-bottom: 20px;
}

.kxsteps .el-step__description {
	padding-right: 0% !important;
	margin-bottom: -10px;
}

.kxsteps .btnitem div {
	color: #a0a0a8;
}

.chanyeTitle .rsTitle {
	margin-top: 0 !important;
}
.kxItemSubContent {
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 10px;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
}
.kxItemSubContent,
.kxItemSubContent p,
.kxItemSubContent a,
.kxItemSubContent p span {
	font-size: 12px !important;
	line-height: 1.7;
	font-weight: normal;
	color: #e1e3f1 !important;
}
.kxItemSubContent img {
	display: none;
}
</style>
<style scoped>
.app_kuaixun {
	display: none;
}
.MainRight {
	margin-left: 30px;
	width: 310px;
}
@media screen and (max-width: 900px) {
	.app_kuaixun {
		display: contents;
	}
	.Main {
		padding-top: 0px;

		background-color: #f2f2f2;
	}
	.MainLeft {
		width: 92%;
		margin: auto;
		display: none;
	}
	.MainRight {
		display: none;
	}
	.kxsteps {
		margin-top: -40 !important;
	}

	.app_kuaixunList {
		width: 100%;
		background: #1c1c1e;
	}

	.kxDate {
		margin-left: 15px;
		margin-right: 6px;
		font-size: 17px;
		font-family: DINPro-Medium, DINPro;
		font-weight: 500;
		color: #fff;
	}
	.kxDate_diff {
		margin-right: 6px;
		font-size: 10px;
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 600;
		color: #fff;
		line-height: 0px;
	}
	.kxDate_week {
		font-size: 10px;
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 600;
		color: #fff;
	}
	.kxSection {
		height: 40px;
		display: flex;
		justify-content: left;
		text-align: center;
	}
	.kxItemSub {
		margin-bottom: 5px;
		/* margin-left: 16px; */
		/* margin-right: 16px; */
		border-radius: 8px;
		display: flex;
		flex-direction: column;
	}
	.kxTimeMain {
		height: 18px;
		width: 45px;
		margin-top: 13px;
		background-color: #f9f9f9;
		display: flex;
		align-items: center;
		/* border-radius: 2px 0px 2px 0px;
		 */
		border-top-left-radius: 9px;
		border-bottom-left-radius: 9px;
	}
	.kxItem {
		display: flex;
		border: 1px solid red;
		padding: 0 15px;
	}
	.kxTimeLine {
		background-color: black;
		width: 2px;
		height: 10px;
	}
	.kxTimeS {
		font-size: 12px;
		line-height: 1;
		font-family: DINPro-Medium, DINPro;
		font-weight: normal;
		color: #b2b2b2;
	}
	.kxItemSubTitle {
		margin-left: 10px;
		margin-right: 10px;
		margin-top: 10px;
		font-size: 16px;
		line-height: 1.5;
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 600;
		color: #fff;
	}

	.kxstepsBoxapp {
		display: flex;
		margin-left: 10px;
		margin-top: 12px;
		margin-bottom: 12px;
	}
	.btnitemapp {
		width: 20vw;
	}
	.btnitemapp div,
	.btnitemapp a {
		margin-left: 4px;
		font-size: 12px;
		font-weight: 400;
		color: #fff;
	}
	.btnitemappimg1 {
		height: 12px;
	}
	.btnitemappimg2 {
		height: 18px;
	}
}
</style>
<style>
.kuai_mobile .el-step.is-vertical .el-step__line {
	top: 0px;
	/* margin-top: 10px; */
}
.kuai_mobile .el-step__head.is-finish .el-step__line {
	top: 10px;
}
.kuai_mobile .el-step__description {
	margin-top: 0;
}

.kuai_mobile .el-step__icon {
	top: 0px;
}

.kuai_mobile .el-step__main {
	padding-top: 0px;
	margin-top: -15px;
}
.kx_pc_item {
	margin-left: 20px;
	margin-top: 0px;
	/* margin-right: 25px; */
}
</style>
